import "./index/style-index.css"
import "./global-helpers/style-crud.css"
import "./global-helpers/style-global.css"
// import "./client-specific/style-zsm.css"
import {Auth}                            from "@aws-amplify/auth"
import Amplify                           from "@aws-amplify/core"

import awsmobile from "../aws-exports.js"
Amplify.configure(awsmobile)

window.jQuery = $;
window.$ = $;

$('#signInForm').on('submit', async function (e) {
    e.preventDefault();
        await signIn();
});

async function signIn() {
    let username = $("#email")
        .val();
    let password = $("#password")
        .val();
    try {
        const user = await Auth.signIn(username, password);
        console.log(user);
        window.location.href = "./homepage.html";
    }
    catch (error) {
        console.log("error signing in", error);
    }
}
